@layer base {
  .main {
  margin: 0 var(--sk-space-32);
  text-align: center;
}

.main___isFull {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - var(--sk-space-72)); /* taking into account the footer place */
}

.main__title {
  margin-top: var(--sk-space-24);
  padding: 0 var(--sk-space-24);
}

.main__confirmationIllustration {
  max-width: 237px;
  height: auto;
}

}