@layer base {
  .fieldGroup {
  margin: 0;
  padding: 0 0 var(--sk-space-24);
  border: none;
}

.messageField {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-12) var(--sk-space-4) var(--sk-space-12) var(--sk-space-16);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-300);
  border-radius: var(--sk-radius-8);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
}

.messageField:focus {
  border-color: var(--sk-color-black);
  box-shadow: 0 0 var(--sk-space-4) 0 rgb(100 96 108 / 15%);
}

.messageField:focus-visible {
  outline: 0;
}

.actions {
  display: flex;
  justify-content: center;
  gap: var(--sk-space-24);
}

}