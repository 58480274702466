@layer modules, ui, base;
@layer ui {
  /** @define Text */

.text_jds-Text__15y8h {
  color: inherit;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.text_jds-Text--normal__VuON_,
.text_jds-Text--edito__aBPHN {
  font-size: var(--body1);
  line-height: var(--body1-lh);
}

.text_jds-Text--small__cqvDw,
.text_jds-Text--label__NchAR {
  font-size: var(--body2);
  line-height: var(--body2-lh);
}

.text_jds-Text--label__NchAR {
  font-weight: var(--semiBold);
  text-transform: uppercase;
}

.text_jds-Text--minor__jABjP,
.text_jds-Text--caption__6nX_h {
  font-size: var(--caption1);
  line-height: var(--caption-lh);
}

.text_jds-Text--heading-extra___Yr_d,
.text_jds-Text--headingBig__eT9R6,
.text_jds-Text--heading-big__oPKWH,
.text_jds-Text--heading__xKbad,
.text_jds-Text--heading-small__0_01a {
  font-family: var(--fontHeading);
  font-weight: var(--bold);
}

.text_jds-Text--heading-extra___Yr_d {
  font-size: var(--display1);
  line-height: var(--display1-lh);
}

.text_jds-Text--headingBig__eT9R6,
.text_jds-Text--heading-big__oPKWH {
  font-size: var(--display2);
  line-height: var(--display2-lh);
}

.text_jds-Text--heading__xKbad {
  font-size: var(--title2);
  line-height: var(--title2-lh);
}

.text_jds-Text--heading-small__0_01a {
  font-size: var(--title3);
  line-height: var(--title3-lh);
}

.text_jds-Text--subhead___TgRV,
.text_jds-Text--subhead-small__5bkWB {
  font-size: var(--title4);
  line-height: var(--title4-lh);
}

.text_jds-Text--caps__D8GoJ {
  text-transform: uppercase;
}

.text_jds-Text--italic__GIuFn {
  font-style: italic;
}

.text_jds-Text--uppercase__hEvTJ {
  text-transform: uppercase;
}

.text_jds-Text--resetSpacing__gNair {
  margin: 0;
  padding: 0;
}

.text_jds-Text--left__IicTW {
  text-align: left;
}

.text_jds-Text--center__d0pko {
  text-align: center;
}

.text_jds-Text--right__hmj0F {
  text-align: right;
}

.text_jds-Text--black__jQhPm {
  color: var(--color-black);
}

.text_jds-Text--white__PkoKT {
  color: var(--color-white);
}

.text_jds-Text--grey__aKOUp {
  color: var(--color-grey--600);
}

.text_jds-Text--greyLight__gZE4a {
  color: var(--color-grey--200);
}

.text_jds-Text--mainColor__A2FdC {
  color: var(--color-black);
}

.text_jds-Text--secondColor__ngyVn {
  color: var(--color-blue);
}

.text_jds-Text--success__l9EGG {
  color: var(--color-success--500);
}

.text_jds-Text--warning__UGG2C {
  color: var(--color-warning--500);
}

.text_jds-Text--error__rkigS {
  color: var(--color-error--500);
}

.text_jds-Text--weight-light__Ob2Dc {
  font-weight: var(--light);
}

.text_jds-Text--weight-normal__uo6Vk {
  font-weight: var(--normal);
}

.text_jds-Text--weight-medium__KxpPm {
  font-weight: var(--medium);
}

.text_jds-Text--weight-semi-bold__eF41S {
  font-weight: var(--semiBold);
}

.text_jds-Text--weight-bold__KNxoa {
  font-weight: var(--bold);
}

.text_jds-Text--weight-extra-bold__o56Nu {
  font-weight: var(--extraBold);
}

.text_jds-Text--weight-black__AYmOH {
  font-weight: var(--black);
}

@media (min-width: 48rem) {
  .text_jds-Text--heading__xKbad {
    font-size: var(--title1);
    line-height: var(--title1-lh);
  }

  .text_jds-Text--heading-small__0_01a {
    font-size: var(--title2);
    line-height: var(--title2-lh);
  }

  .text_jds-Text--subhead___TgRV,
  .text_jds-Text--subhead-small__5bkWB {
    font-size: var(--title3);
    line-height: var(--title3-lh);
  }
}

}
@layer ui {
  /** @define Illustration */
.Illustration_jds-Illustration--big__I2Ypf {
  height: 320px;
}

.Illustration_jds-Illustration--small__QCVX_ {
  height: 200px;
}

}
@layer base {
  .ReportConfirmation_confirmation__uI2Fh {
  text-align: center;
}

.ReportConfirmation_confirmationIllustration__WA3Fj {
  max-height: 200px;
}

.ReportConfirmation_confirmationTitle__enxoP {
  margin-top: var(--sk-space-24);
}

.ReportConfirmation_confirmationAction__vh44t {
  margin: var(--sk-space-24) 0;
}

}
@layer base {
  .ReportFlow_fieldGroup__y_IFQ {
  margin: 0;
  padding: 0 0 var(--sk-space-24);
  border: none;
}

.ReportFlow_messageField__pENnK {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-12) var(--sk-space-4) var(--sk-space-12) var(--sk-space-16);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-300);
  border-radius: var(--sk-radius-8);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
}

.ReportFlow_messageField__pENnK:focus {
  border-color: var(--sk-color-black);
  box-shadow: 0 0 var(--sk-space-4) 0 rgb(100 96 108 / 15%);
}

.ReportFlow_messageField__pENnK:focus-visible {
  outline: 0;
}

.ReportFlow_actions__7qA7e {
  display: flex;
  justify-content: center;
  gap: var(--sk-space-24);
}

}
@layer base {
  .SuccessLayout_main__4GN7K {
  margin: 0 var(--sk-space-32);
  text-align: center;
}

.SuccessLayout_main___isFull__XsWnN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - var(--sk-space-72)); /* taking into account the footer place */
}

.SuccessLayout_main__title__loCll {
  margin-top: var(--sk-space-24);
  padding: 0 var(--sk-space-24);
}

.SuccessLayout_main__confirmationIllustration__gL5Vs {
  max-width: 237px;
  height: auto;
}

}
@layer base {
  /**
 * Links
 * @see https://github.com/jobteaser/spark/blob/main/packages/library/src/components/Link/sk-Link.module.css
 */
.RichText_main__L_7T3 a {
  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.RichText_main__L_7T3 a:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.RichText_main__L_7T3 a:focus-visible {
  outline: var(--sk-navigation-link-color-border-focus-light) solid var(--sk-navigation-link-size-border-focus);
  outline-offset: 0;
}

/* Table */
.RichText_main__L_7T3 table {
  width: 100%;
  table-layout: fixed;
}

.RichText_main__L_7T3 table,
.RichText_main__L_7T3 td,
.RichText_main__L_7T3 tr {
  border-collapse: collapse;
  border: 1px solid var(--sk-color-black);
  vertical-align: top;
}

.RichText_main__L_7T3 td {
  padding: var(--sk-space-8);
}
}
@layer base {
  .TermsOfUse_main__aaa3I {
  margin: var(--sk-space-24) 0;
}

.TermsOfUse_main__aaa3I a {
  color: var(--sk-color-grey-900);
}

@media (min-width: 1312px) {
  .TermsOfUse_main__aaa3I {
    margin: var(--sk-space-32) 0;
  }
}

}
@layer base {
  .PageLayoutAside_aside__N6yqY {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
}

.PageLayoutAside_aside___closing__CxRdH {
  /*
    The JS relies on the `animationend` event to close the Application Flow.
    So, we need to define a no-op animation for small and medium screens.
  */
  animation: PageLayoutAside_no-op__EPd9U forwards;
}

.PageLayoutAside_aside___similarJobads__opCE_ {
  display: none;
}

@keyframes PageLayoutAside_no-op__EPd9U {
  0%, 100% {
    transform: none;
  }
}

@media (min-width: 1312px) {
  .PageLayoutAside_aside__N6yqY {
    --application-flow-width: 31vw;
    content: "displayedAsPanel"; /* used on javascript side */
    position: absolute;
    z-index: var(--z-up-in-the-current-stacking-context);
    width: var(--application-flow-width);
    max-width: 560px;
    height: 100%;
    transform: translateX(calc(-1 * var(--sk-space-24)));
    animation: PageLayoutAside_slide-in-from-right__YfPEK 0.5s;
    inset: 0 0 0 auto;
  }

  .PageLayoutAside_aside___similarJobads__opCE_ {
    display: block;
  }

  .PageLayoutAside_aside___closing__CxRdH {
    animation: PageLayoutAside_slide-out-from-right__1V44c 0.5s;
    opacity: 0;
  }

  @keyframes PageLayoutAside_slide-in-from-right__YfPEK {
    0% {
      transform: translateX(0);
      opacity: 0;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }
  }

  @keyframes PageLayoutAside_slide-out-from-right__1V44c {
    0% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 0;
    }
  }
}

}
@layer ui {
  /** @define Error */

.error_jds-Error__6hhrs {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--spacer-300);
  border: 2px solid var(--color-black);
  border-radius: var(--radius-200);
}

.error_jds-Error__contentWrapper__6tIR_ {
  margin-bottom: var(--spacer-300);
}

.error_jds-Error__illustrationWrapper__9_Fom {
  align-self: center;
  width: 100%;
  max-width: 300px;
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.error_jds-Error__illustration__vsi6t.error_jds-Error__illustration__vsi6t { /* needed to override class weight from Illustration component */
  width: 100%;
  height: auto;
}

@media (min-width: 48rem) {
  .error_jds-Error__6hhrs {
    flex-direction: row;
  }

  .error_jds-Error__contentWrapper__6tIR_ {
    flex: 3 1 0;
    margin-right: var(--spacer-300);
    margin-bottom: 0;
  }

  .error_jds-Error__illustrationWrapper__9_Fom {
    flex: 2 1 0;
    width: auto;
  }
}

}
@layer base {
  .Error_message__zrci3 {
  margin-top: 400px;
}

}
