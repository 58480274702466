@layer base {
  .confirmation {
  text-align: center;
}

.confirmationIllustration {
  max-height: 200px;
}

.confirmationTitle {
  margin-top: var(--sk-space-24);
}

.confirmationAction {
  margin: var(--sk-space-24) 0;
}

}